import React from "react";

export default function Resume() {
  return (
    <>
      <main className="pb-5 mt-5 pt-5">
        <div className="container py-4 d-flex flex-column">
          <div className="title mb-5 text-center">
            <h1 style={{ fontSize: "55px" }}>Resume</h1>
          </div>
          <span className="fs-4">Sorry , Resume Is Under Development 😞</span>
        </div>
      </main>
    </>
  );
}
